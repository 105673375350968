<template>
  <div class="steps__wrapper">
    <div class="steps">
      <div
        class="steps__button steps__button_prev"
        @click="$emit('prevStep')"
        v-if="!hideArrows"
      >
        <div class="steps__button-arrow">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 4.5L3.75 12L11.25 19.5"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M5.25 12L20.25 12"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
        Назад
      </div>
      <div class="steps__row">
        <div
          class="steps__item"
          :class="{
            steps__item_filled: activeStep > stepNumber,
            steps__item_active: activeStep == stepNumber,
          }"
          v-for="stepNumber in stepsCount"
          :key="stepNumber"
        >
          {{ stepNumber }} / {{ stepsCount }}
        </div>
      </div>
      <div
        class="steps__button steps__button_next"
        :class="{
          'steps__button steps__button_next_disabled': disableNext,
          'steps__button steps__button_next_hide': activeStep == 18,
        }"
        @click="nextStep()"
        v-if="!hideArrows"
      >
        Далее
        <div class="steps__button-arrow">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.75 4.5L20.25 12L12.75 19.5"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
            <path
              d="M18.75 12L3.75 12"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="button button_pink mt-4 mx-auto" @click="refreshClinicalCase" v-if="activeStep !== 1 && activeStep !== 18">
      Начать заново
      <svg
        class="ml-2"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.42727 2.60871C4.78495 2.33049 5.16367 2.07258 5.63465 1.8178C7.3567 0.881527 9.31343 0.486072 11.2248 0.647068C13.0926 0.804937 14.9198 1.49581 16.4477 2.73689C16.9963 3.18236 17.4802 3.66066 17.8994 4.16396C19.3447 5.90053 20.0585 7.95909 20.039 10.0114C20.018 12.0637 19.2654 14.1082 17.7764 15.8166C17.3427 16.3152 16.849 16.7826 16.2988 17.2093C15.0105 18.2112 13.5004 18.8552 11.9062 19.0975C10.3412 19.3366 8.6968 19.1897 7.10584 18.6176C5.59257 18.0721 4.24438 17.239 3.18752 16.1511C2.18245 15.1164 1.44281 13.8487 1.0738 12.381C0.958888 11.9215 0.88282 11.4525 0.852069 10.9758C0.819699 10.4975 0.831029 10.0083 0.887675 9.50652C0.923281 9.19235 1.21622 8.9657 1.54154 9.00009C1.86685 9.03448 2.10153 9.31739 2.06592 9.63157C2.01575 10.0661 2.00604 10.4897 2.03355 10.9039C2.06107 11.3181 2.12581 11.7214 2.22291 12.1122C2.54013 13.3798 3.17943 14.4724 4.05017 15.3696C4.98241 16.3277 6.17522 17.0639 7.51855 17.5485C8.91205 18.0502 10.3525 18.18 11.7201 17.9705C13.1104 17.7579 14.4294 17.1952 15.5559 16.3215C16.0527 15.9354 16.4914 15.5212 16.8717 15.0851C18.1778 13.5861 18.8381 11.798 18.8559 10.0051C18.8737 8.2123 18.2474 6.41009 16.9769 4.88454C16.5998 4.43125 16.1709 4.0061 15.687 3.61377C14.3566 2.53212 12.7592 1.93034 11.1229 1.79123C9.44453 1.64899 7.72571 1.99756 6.21406 2.81816C5.75765 3.06669 5.3935 3.31991 5.04229 3.60126L7.42144 3.6841C7.74837 3.69505 8.00409 3.9592 7.99114 4.27494C7.97981 4.59068 7.70629 4.83765 7.37936 4.82514L3.5889 4.69228C3.26197 4.68134 3.00625 4.41718 3.0192 4.10144C3.0192 4.08112 3.02244 4.06237 3.02406 4.04361L3.54035 0.495449C3.58566 0.182836 3.88346 -0.0359918 4.20878 0.00777421C4.53247 0.0515402 4.75906 0.339144 4.71374 0.65332L4.42727 2.60871Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: {
    stepsCount: {
      type: Number,
      default: 1,
    },
    activeStep: Number,
    hideArrows: Boolean,
    disableNext: Boolean,
  },
  methods: {
    nextStep() {
      if (!this.disableNext) {
        this.$emit("nextStep");
      }
    },
    refreshClinicalCase() {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "clinical case", {
          "Клинический случай": {
            "Пройти заново": {
              Params: {
                ...vm.$root.ymFields,
              },
            },
          },
        });
      }
      this.$emit("refreshClinicalCase");
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 0 30px;
  }

  &__item {
    font-size: 0;
    width: 30px;
    height: 4px;
    border-radius: 99px;
    background-color: #e1aecb;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    color: #ffffff;

    @media screen and (max-width: 1220px) {
      width: 10px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    &_filled {
      background-color: #830051;
    }

    &_active {
      width: auto;
      height: auto;
      padding: 8px 14px 7px;
      background-color: #830051;
      font-size: 12px;
      display: block;
    }
  }

  &__button {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1f1f1f;
    gap: 12px;
    cursor: pointer;
    user-select: none;

    &_next {
      margin-left: auto;

      &_disabled {
        cursor: not-allowed;
        color: rgba(31, 31, 31, 0.7);

        & .steps__button-arrow {
          color: rgba(139, 142, 142, 0.7);
          background-color: rgba(255, 255, 255, 0.7);
        }
        &::before {
          position: absolute;
          opacity: 0;
          transition: 0.3s;
          bottom: calc(100% + 5px);
          right: 0;
          z-index: 3;
          pointer-events: none;
          display: inline-block;
          width: fit-content;
          padding: 8px 16px;
          background-color: #f8dcdc;
          border: 1px solid #cf1517;
          border-radius: 8px;
          font-family: "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #1f1f1f;
          content: "Пройдите тест";
          white-space: nowrap;
        }

        &:hover {
          &:hover {
            color: rgba(31, 31, 31, 0.7);

            & .steps__button-arrow {
              color: rgba(139, 142, 142, 0.7);
            }
          }
          &::before {
            opacity: 1;
          }
        }
      }

      &_hide {
        opacity: 0;
        pointer-events: none;
      }
    }

    &_prev {
      margin-right: auto;
    }

    &:hover {
      color: #830051;

      & .steps__button-arrow {
        color: #830051;
      }
    }

    &-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8b8e8e;
      transition: 0.3s;
    }
  }
}
</style>